import { http } from '../../http';

export default {
  getDocuments(params) {
    return http.get('/documents', { params });
  },

  getDocumentsByCategorySlug(slug) {
    return http.get(`/documents/${slug}`);
  },

  getDocumentBySlug(categorySlug, slug) {
    return http.get(`/documents/${categorySlug}/${slug}`);
  },
};
