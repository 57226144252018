import 'vue-toastification/dist/index.css';

import toast, {
  PluginOptions,
  POSITION,
} from 'vue-toastification/dist/index.mjs';

export default defineNuxtPlugin((nuxtApp) => {
  const options: PluginOptions = {
    position: POSITION.BOTTOM_RIGHT,
    timeout: 2500,
    closeOnClick: false,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: false,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: true,
    rtl: false,
  };
  nuxtApp.vueApp.use(toast, options);
});
