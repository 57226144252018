import { http } from '../../http';

export default {
  getChats() {
    return http.get('/gpt/chats');
  },

  deleteChat(chatId) {
    return http.delete(`/gpt/chat/${chatId}`);
  },

  getHistory(chatId, fromMessage = null) {
    const route = fromMessage
      ? `/gpt/history/${chatId}/${fromMessage}`
      : `/gpt/history/${chatId}`;
    return http.get(route);
  },

  sendMessage(data) {
    const route = data.chatId ? `/gpt/send/${data.chatId}` : '/gpt/send';
    return http.post(route, { message: data.message });
  },

  refreshLast(chatId) {
    return http.get(`/gpt/request-again/${chatId}`);
  },
};
