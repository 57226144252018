// import Vue from 'vue';
// import VueLazyload from 'vue-lazyload';

// import error from '~/static/default-image.png';
// import loading from '~/static/loading.svg';

// const addHostToSrc = (
//   src,
//   host = process.env.NUXT_APP_IMG_HOST ?? 'https://histrf.ru',
// ) => {
//   /** https://delo1.jet-mix.ru */
//   if (!src) return src;

//   if (
//     src.startsWith('http') ||
//     src.startsWith('/_nuxt') ||
//     src.startsWith(host)
//   ) {
//     return src;
//   }

//   return host + src;
// };

// Vue.use(VueLazyload, {
//   preLoad: 1,
//   error,
//   loading,
//   attempt: 1,
//   filter: {
//     jpg(listener) {
//       if (
//         process.env.NODE_ENV === 'development' ||
//         (process.client && window.location.host === 'delo1.jet-mix.ru')
//       ) {
//         listener.src = addHostToSrc(listener.src);
//       }
//     },
//   },
// });

export default defineNuxtPlugin(() => {});
