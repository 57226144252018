import { http } from '../../http';

export default {
  login(user) {
    return http.post('/token', user);
  },

  register(user) {
    return http.post('/register', user);
  },

  forgotPassword(email) {
    return http.post('/forgot-password', email, {
      'content-type': 'application/json',
      Accept: 'application/json',
    });
  },

  resetPassword(data) {
    return http.post('/reset-password', data);
  },

  vkIdAuth(data) {
    return http.post('/login/vk-id/callback', data);
  },
};
