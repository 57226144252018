import { http } from '../../http';

export default {
  getAuthors(params = {}, filter = {}) {
    return http.get('/authors', {
      params: {
        per_page: params.per_page || 12,
        page: params.page || 1,
        sort_by: filter.sort_by,
      },
    });
  },

  getAuthorBySlug(slug) {
    return http.get(`/authors/${slug}`);
  },

  getAuthorsContent(slug, model, params = {}) {
    return http.get(`/authors/${slug}/${model}`, {
      params: {
        per_page: params.per_page || 12,
        page: params.page || 1,
        // sort_by: params.sortBy,
      },
    });
  },

  getPopularAuthors() {
    return http.get(`/popular/authors`);
  },

  getAuthorsWithPhoto(params = {}) {
    return http.get('/authors/photo', {
      params: {
        per_page: params.per_page || 4,
      },
    });
  },
};
