import { http } from '../../http';

export default {
  getTests(params = {}, filter = {}) {
    return http.get('/tests', {
      params: {
        per_page: params.per_page || 12,
        page: params.page || 1,
        sort_by: filter.sort_by,
        categories: filter.category,
        start_century: params.startCentury,
        end_century: params.endCentury,
      },
    });
  },

  getTestsCategories() {
    return http.get('/tests/categories');
  },

  getTestBySlug(slug) {
    return http.get(`/tests/${slug}`);
  },

  loadTestResults(id, params) {
    return http.get('/tests/result/' + id, {
      params,
    });
  },

  updateCountAnswers(questionID, answerID) {
    return http.patch(`/questions/${questionID}/answers/${answerID}`);
  },
};
