export const useAudioStore = defineStore('audio', {
  state: () => ({
    isShowFixedAudioBar: false,
    data: null,
    src: null,
    audio: null,
    current_audio_data: null,
    played: false,
    current: null,
    currentTime: 0,
    duration: 0,
    loaded: false,
    current_category_list: null,
    current_audio_list: [],
  }),
  actions: {
    start({ audio, data }) {
      if (!audio) return;

      if (this.audio !== null) {
        this.stop();
      }

      this.duration = parseInt(audio.duration);
      this.data = data;
      this.audio = audio;
      this.volume = 0.5;

      this.audio.addEventListener('timeupdate', () => {
        this.currentTime = this.audio ? this.audio.currentTime : 0;
      });

      this.loaded = true;

      this.play();
    },

    play() {
      this.audio.play();
      this.played = true;
    },

    async pause() {
      if (this.audio !== null) {
        this.audio.pause();
      }

      this.played = false;
    },

    async stop() {
      this.pause();

      if (this.audio !== null) {
        this.audio.currentTime = 0;
        this.audio.removeEventListener('timeupdate', () => {
          this.currentTime = this.audio ? this.audio.currentTime : 0;
        });
      }

      this.currentTime = 0;
      this.data = null;
      this.audio = null;
    },

    close() {
      this.loaded = false;
      this.stop();
    },

    move(percentage) {
      if (this.audio !== null) {
        this.audio.currentTime = (this.duration / 100) * percentage;
      }
    },

    showFixedAudioBar() {
      this.isShowFixedAudioBar = true;
    },

    hideFixedAudioBar() {
      this.isShowFixedAudioBar = false;
    },

    setCurrent(cur) {
      this.current = cur;
    },

    setCurrentAudioData(data) {
      this.current_audio_data = data;
    },

    setCurrentAudioList(data) {
      this.current_audio_list = data;
    },

    setCurrentCategoryList(category) {
      this.current_category_list = category;
    },

    rmItemFromList(index) {
      this.current_audio_list.splice(index, 1);
    },
  },
  getters: {
    getData: (state) => state.data,
    getAudio: (state) => state.audio,
    getCurrent: (state) => state.current,
    getCurrentAudioData: (state) => state.current_audio_data || null,
    getCurrentAudioList: (state) => state.current_audio_list || [],
    getCurrentCategoryList: (state) => state.current_category_list,
    getSrc: (state) => {
      return state.audio ? state.audio.src : false;
    },
    isPlayed: (state) => state.played,
    getCurrentTime: (state) => Math.round(state.currentTime),
    getDuration: (state) => state.duration,
    getPassed: (state) => {
      if (state.audio === null) {
        return 0;
      }
      return state.currentTime / (state.duration / 100);
    },
    isLoaded: (state) => state.loaded,
    isFixedAudioBarShown: (state) => state.isShowFixedAudioBar,
  },
});
