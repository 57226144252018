import { http } from '../../http';

export default {
  // audio
  getAudioCourses(params = {}) {
    return http.get('/courses/audio', {
      params: {
        per_page: params.per_page || 100,
        page: params.page || 1,
        category: params.category,
      },
    });
  },

  // video
  getVideoCourses(params = {}) {
    return http.get('/courses/video', {
      params: {
        per_page: params.per_page || 100,
        page: params.page || 1,
        category: params.category,
      },
    });
  },

  // regular
  getRegularCourses(params = {}) {
    return http.get('/courses/courses', {
      params: {
        per_page: params.per_page || 100,
        page: params.page || 1,
      },
    });
  },

  // common
  getCourseBySlug(slug) {
    return http.get(`/courses/${slug}`);
  },

  getCoursesCategories(type) {
    // audiocourse || videocourse
    return http.get(`/categories/course?type=${type}`);
  },

  getTeachers(params = {}) {
    return http.get('/courses/teacher', {
      params: {
        per_page: params.per_page || 18,
        page: params.page || 1,
        ...(params.category && { category: params.category }),
      },
    });
  },

  getTeachersBySlug(slug) {
    return http.get(`/courses/${slug}`);
  },

  getTeachersVideoCategories() {
    return http.get(`/courses/teacher/categories`);
  },
};
