import { http } from '../../http';

export default {
  handleLike(params) {
    return http.get(`/like`, { params });
  },

  handleDisLike(params) {
    return http.get(`/dislike`, { params });
  },

  handleSetReaction(params) {
    return http.post(`/like/reaction-set`, params);
  },

  handleUnsetReaction(params) {
    return http.post(`/like/reaction-unset`, params);
  },

  handleBookmark(params) {
    return http.post(`/bookmarks/set`, params);
  },
};
