import { http } from '../../http';

export default {
  getCategories() {
    return http.get(`/courses/teacher/categories`);
  },
  getTeacherCategoies() {
    return http.get(`/teachers?by_shelf=1`);
  },
  getSubjectChapter(id) {
    return http.get(`/teachers/chapters/${id}`);
  },
  getGlossary(slug) {
    return http.get(`/teachers/glossary/${slug}`);
  },
};
