export const useMenuStore = defineStore('menu', {
  state: () => ({
    // состояния влияют на position fixed у body и z-index у header
    showMobileMenu: false,
    showNestedMenu: false,
    showUserMenu: false,
  }),

  actions: {
    openUserMenu() {
      this.showUserMenu = true;
    },
    closeUserMenu() {
      this.showUserMenu = false;
    },
    openMobileMenu() {
      this.showMobileMenu = true;
    },
    closeMobileMenu() {
      this.showMobileMenu = false;
    },
    toggleNestedMenu() {
      this.showNestedMenu = !this.showNestedMenu;
    },
    closeAllMenu() {
      this.showMobileMenu = false;
      this.showNestedMenu = false;
      this.showUserMenu = false;
    },
  },

  getters: {
    isMobileMenuShown: (state) => state.showMobileMenu,
    isAnyMenuShown: (state) =>
      state.showMobileMenu || state.showNestedMenu || state.showUserMenu,
  },
});
