import { http } from '../../http';

export default {
  getGames(params = {}) {
    return http.get('/teachers/events', {
      params: {
        year: params.year ?? new Date().getFullYear(),
        month: params.month ?? new Date().getMonth() + 1,
        region: params.region,
      },
    });
  },

  getGameRegions() {
    return http.get('/teachers/events/regions');
  },
};
