import axios from 'axios';

import { setClient } from '@/services/http';

export default defineNuxtPlugin(() => {
  const authStore = useAuthStore();

  const accessToken = computed(() => authStore.getToken);

  const {
    public: { api },
  } = useRuntimeConfig();

  const config = {
    baseURL: api,
    headers: {
      common: {},
    },
  };

  const client = axios.create(config);

  client.interceptors.request.use(
    (config) => {
      config.headers = config.headers ?? {};
      if (accessToken.value) {
        config.headers.Authorization = `Bearer ${accessToken.value}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  setClient(client);
});
