import { http } from '../../http';

export default {
  getPinnedArticles() {
    return http.get('/pinned/articles');
  },

  getPinnedVideoMaterials() {
    return http.get('/pinned/videomaterials');
  },

  getPinnedAudioLectures() {
    return http.get('/pinned/audiolectures');
  },

  getPinnedElements() {
    return http.get('/main-page-elements');
  },
};
