export const useModalsStore = defineStore('modals', {
  state: () => ({
    shown_modal: null,
  }),

  actions: {
    setShownModal(modalName) {
      this.shown_modal = modalName; //|| arguments[1];  TODO
      document.body.classList.add('body-modal-shown');
    },
    showSocialAuthForm() {
      this.setShownModal('SocialAuthForm');
    },

    showEmailAuthForm() {
      this.setShownModal('EmailAuthForm');
    },

    showResetPasswordForm() {
      this.setShownModal('ResetPasswordForm');
    },

    showNewPasswordForm() {
      this.setShownModal('NewPasswordForm');
    },

    showRegistrationForm() {
      this.setShownModal('RegistrationForm');
    },

    showSearch() {
      this.setShownModal('TheSearch');
    },

    showAskQuestion() {
      this.setShownModal('AskQuestion');
    },

    showFeedbackForm() {
      this.setShownModal('FeedbackForm');
    },

    closeModal() {
      this.shown_modal = null;
      document.body.classList.remove('body-modal-shown');
    },
  },

  getters: {
    getShownModal(state) {
      return state.shown_modal;
    },
  },
});
