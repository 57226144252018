import { http } from '../../http';

export default {
  getCollections(params = {}) {
    return http.get('/highlights', {
      params: {
        page: params.page || 1,
        per_page: params.per_page || 8,
      },
    });
  },

  getCollectionBySlug(slug) {
    return http.get(`/highlights/${slug}`);
  },

  getCollectionItemsBySlug(slug, type) {
    return http.get(`/highlights/${slug}/collection`, { params: { type } });
  },

  getThemeOfTheWeek() {
    return http.get('/themeoftheweek');
  },

  getThematic() {
    return http.get('/highlights/thematic');
  },

  getMemDates() {
    return http.get('/highlights/pamyatnye-daty/collection?type=videomaterial');
  },
};
