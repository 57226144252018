import { http } from '../../http';

export default {
  getComments(params = {}) {
    return http.get('/comments', {
      params: {
        model_type: params.modelType,
        id: params.modelId,
      },
    });
  },

  getPopularComments(amount = 8) {
    return http.get(`/popular/comments?qty=${amount}`);
  },

  getCommentAnswers(id) {
    return http.get(`/comments/answers/${id}`);
  },

  getCommentsCategories() {
    return http.get(`/comments/categories/`);
  },

  getCommentsCategoriesForModel(model, id) {
    return http.get(`/comments/categories/${model}/${id}`);
  },

  postComment(data) {
    return http.post(`/comments`, data);
  },

  deleteComment(id) {
    return http.delete(`/comments/${id}`);
  },

  rateCommentUp(model, id) {
    return http.get(`/rateup?model_type=${model}&id=${id}`);
  },

  rateCommentDown(model, id) {
    return http.get(`/ratedown?model_type=${model}&id=${id}`);
  },
};
