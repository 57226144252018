<template>
  <div class="fixed inset-0">
    <table class="size-full">
      <tbody>
        <tr>
          <td class="text-center align-middle">
            <div
              v-if="!isClearError && error && error.statusCode === 404"
              class="flex flex-col items-center"
            >
              <h1 class="mb-4 text-heading-h2">Страница не найдена!</h1>
              <p>
                Вы можете перейти на
                <span
                  class="cursor-pointer text-ruby-red-100 underline"
                  @click="handleError"
                  >главную страницу</span
                >.
              </p>
            </div>

            <template
              v-else-if="
                !isClearError &&
                error &&
                error.statusCode &&
                error.statusCode !== 404
              "
            >
              <p class="error-text mx-auto max-w-[600px]">
                Сайт временно недоступен, мы уже решаем данную проблему,
                приносим свои извинения.
              </p>
              <p class="error-text mx-auto mt-10">
                <svg
                  width="200"
                  height="226"
                  viewBox="0 0 200 226"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="inline"
                  @dblclick="isShowError = !isShowError"
                >
                  <g opacity="0.7">
                    <g style="mix-blend-mode: multiply" opacity="0.4">
                      <path
                        d="M99.9999 226C155.228 226 200 224.771 200 223.255C200 221.739 155.228 220.51 99.9999 220.51C44.7715 220.51 0 221.739 0 223.255C0 224.771 44.7715 226 99.9999 226Z"
                        fill="#BB050F"
                      />
                    </g>
                    <path
                      d="M23.5602 185.035C19.4578 185.035 15.4266 183.962 11.8653 181.922C8.30406 179.883 5.33623 176.948 3.25548 173.407C1.17474 169.867 0.0531965 165.843 0.00184625 161.735C-0.049504 157.627 0.971113 153.577 2.9627 149.985L79.4041 12.1384C83.6187 4.53706 91.3174 0 99.9999 0C108.682 0 116.381 4.53706 120.596 12.1384L197.037 149.985C199.029 153.577 200.049 157.627 199.998 161.735C199.947 165.843 198.825 169.866 196.745 173.407C194.664 176.947 191.697 179.882 188.136 181.922C184.575 183.961 180.543 185.034 176.441 185.035H23.5602Z"
                      fill="white"
                    />
                    <path
                      d="M7.18823 152.338L83.6296 14.4895C90.7625 1.62456 109.236 1.62456 116.366 14.4895L192.807 152.338C199.738 164.835 190.713 180.193 176.437 180.193H23.558C9.28236 180.193 0.257709 164.84 7.18823 152.338Z"
                      fill="#BB050F"
                    />
                    <g style="mix-blend-mode: multiply" opacity="0.21">
                      <path
                        d="M107.192 174.016C128.155 153.091 149.075 132.122 169.952 111.112L144.936 65.9934L92.2699 118.735L34.8253 176.272L30.9102 180.193H100.989C103.046 178.132 105.126 176.079 107.192 174.016Z"
                        fill="#7E0007"
                      />
                    </g>
                    <g style="mix-blend-mode: multiply" opacity="0.43">
                      <path
                        d="M172.381 115.506C163.192 124.739 154.002 133.973 144.813 143.208C132.503 155.55 120.178 167.879 107.838 180.193H176.436C190.711 180.193 199.736 164.835 192.805 152.338L172.381 115.506Z"
                        fill="#A7040C"
                      />
                    </g>
                    <path
                      d="M87.7503 127.504L82.8398 73.0874V72.061C82.8398 71.441 82.9618 70.827 83.1987 70.2541C83.4356 69.6813 83.7829 69.1608 84.2207 68.7223C84.6585 68.2839 85.1783 67.9361 85.7503 67.6988C86.3223 67.4615 86.9354 67.3394 87.5545 67.3394H112.45C113.701 67.3394 114.9 67.8369 115.784 68.7223C116.668 69.6078 117.165 70.8088 117.165 72.061V73.0874L112.148 127.512C112.044 128.632 111.527 129.672 110.697 130.429C109.868 131.186 108.786 131.606 107.663 131.606H92.2346C91.1108 131.606 90.0276 131.186 89.1978 130.426C88.368 129.667 87.8517 128.625 87.7503 127.504ZM86.9722 153.161V141.555C86.9722 140.594 87.3535 139.672 88.0322 138.992C88.7109 138.313 89.6314 137.931 90.5912 137.931H109.299C110.258 137.931 111.179 138.313 111.858 138.992C112.536 139.672 112.918 140.594 112.918 141.555V153.161C112.918 154.123 112.536 155.045 111.858 155.724C111.179 156.404 110.258 156.786 109.299 156.786H90.5912C89.6314 156.786 88.7109 156.404 88.0322 155.724C87.3535 155.045 86.9722 154.123 86.9722 153.161Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </p>
              <div v-if="error" class="fixed inset-x-4 bottom-4">
                <div
                  :class="isShowError ? 'h-auto p-2' : 'h-0'"
                  class="max-h-60vh custom-scrollbar overflow-auto rounded-xl shadow-drop-6"
                >
                  <pre>{{ error }}</pre>
                </div>
              </div>
            </template>
            <template v-else>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                style="
                  margin: auto;
                  background: none;
                  display: block;
                  shape-rendering: auto;
                "
                width="800px"
                height="800px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <circle
                  cx="50"
                  cy="50"
                  r="5"
                  stroke-width="1"
                  stroke="rgba(255, 29, 94, 0.75)"
                  stroke-dasharray="7.853981633974483 7.853981633974483"
                  fill="none"
                  stroke-linecap="round"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1.5384615384615383s"
                    keyTimes="0;1"
                    values="0 50 50;360 50 50"
                  ></animateTransform>
                </circle>
              </svg>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
const error = useError();
const isShowError = ref(false);
const isClearError = ref(false);
const handleError = () => {
  isClearError.value = true;
  clearError({ redirect: '/' });
};
</script>

<style lang="scss" scoped>
.error-text {
  font-family: 'Roboto', sans-serif;
  font-size: 1.125rem;
  line-height: 1.575rem;
  color: #1f1f1f;
}
</style>
