import { http } from '../../http';

export default {
  getAllEvents(params = {}) {
    return http.get('/afisha/all', {
      params: {
        per_page: params.per_page || 12,
        page: params.page || 1,
      },
    });
  },

  getFilteredEvents(params = {}) {
    return http.get(`/afisha/all`, {
      params: {
        page: params?.page || 1,
        per_page: params?.perPage || 12,
        city: params.city,
        categories: params.categories,
      },
    });
  },

  getEventsCategories() {
    return http.get(`/afisha/categories`);
  },

  getEventBySlug(slug) {
    return http.get(`/afisha/${slug}`);
  },
};
