import { http } from '../../http';

export default {
  loadSurvey(id, answerIds) {
    return http.patch('/surveys/' + id, {
      data: {
        relationships: {
          answers: {
            data: answerIds.map((id) => ({
              id,
            })),
          },
        },
      },
    });
  },

  getMainSurvey() {
    return http.get('/pinned/survey');
  },

  getPinnedSurveys() {
    return http.get('pinned/surveys');
  },
};
