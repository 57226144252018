import axios from 'axios';

export default {
  getDostoevskyBooks(count = 9) {
    return axios({
      url: '/catalog/books/history/',
      baseURL: 'https://api.dostoevsky-club.ru/api/v1',
      params: {
        count,
      },
    });
  },
};
