let client;

export function setClient(axios) {
  client = axios;
}

function call(method, ...args) {
  return client[method](...args);
}

export const http = {
  get(path, config) {
    return call('get', path, config);
  },

  post(path, data, config) {
    return call('post', path, data, config);
  },

  put(path, data, config) {
    return call('put', path, data, config);
  },

  patch(path, data, config) {
    return call('patch', path, data, config);
  },

  delete(path, config) {
    return call('delete', path, config);
  },
};
