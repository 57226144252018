import { http } from '../../http';

export default {
  getUserInfo() {
    return http.get('/profile');
  },

  getUserBookmarks(params = {}) {
    return http.get('/bookmarks', {
      params: {
        per_page: params.per_page || 12,
        page: params.page || 1,
      },
    });
  },

  getUserTests(params = {}) {
    return http.get('/profile/tests', {
      params: {
        per_page: params.per_page || 12,
        page: params.page || 1,
      },
    });
  },

  getUserComments(params = {}) {
    return http.get('/profile/comments', {
      params: {
        per_page: params.per_page || 6,
        page: params.page || 1,
      },
    });
  },

  updateUserInfo(formData) {
    return http.post('/profile', formData);
  },

  getSubscriptionAuthors(params = {}) {
    return http.get(`/subscriptions/authors/`, {
      params: {
        page: params.page || 1,
        per_page: params.perPage || 8,
      },
    });
  },

  getSubscriptionAuthorArticles(authorId) {
    return http.get(`/subscriptions/authors/${authorId}`);
  },

  subscribeAuthor(authorId) {
    return http.get(`/subscriptions/authors/subscribe/${authorId}`);
  },

  // обновление даты, от которой отсчитываются и выводятся новые материалы автора
  updateAuthorNewArticlesDate(authorId) {
    return http.get(`/subscriptions/authors/update/${authorId}`);
  },
};
