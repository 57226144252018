import { http } from '../../http';

export default {
  getTimeline(filter = {}) {
    return http.get('/timeline', {
      params: {
        page: 1,
        per_page: 1000,
        start_year: filter.start_year || 1800,
        end_year: filter.end_year || 1899,
      },
    });
  },
};
