import { http } from '@/services/http';

export default {
  // getMagazines() {
  //   return http.get('/magazines');
  // },
  getMagazineRelease(slug) {
    return http.get(`/magazines/${slug}`);
  },
  getMagazineArticle(slug) {
    return http.get(`/magazines/articles/${slug}`);
  },
};
